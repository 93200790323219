import React, { FC } from "react";
import RellaxWrapper from "react-rellax-wrapper";
import BlockWrapper from "../features/BlockWrapper";
import TextGrid, { TextGridWrapperAbsolute } from "../features/TextGrid";
import { useResponsive } from "../hooks/useResponsive";
import Claim from "../partials/Claim";
import Menu from "../partials/Menu";
import Sections from "../partials/Sections";
import { breakpoints } from "../styling/theme";
import Background from "../ui/Background";
import FlexBox from "../ui/FlexBox";
import { getCoverUrl } from "../utils/helpers";
import {
  CollectionDataT,
  PortfolioCategoryStriperT,
  SectionT,
} from "../utils/types";

type CategoryWrapperT = {
  coverData: PortfolioCategoryStriperT;
  sectionsData?: CollectionDataT<SectionT>;
};

const CategoryWrapper: FC<CategoryWrapperT> = ({ coverData, sectionsData }) => {
  const { tabletVersion, mobileVersion } = useResponsive(breakpoints.sm);

  return (
    <FlexBox flexDirection="column">
      <Menu isHomePage={false} />
      <Claim />
      <BlockWrapper
        cover={
          <>
            <Background
              url={getCoverUrl(
                tabletVersion || mobileVersion,
                coverData.render.desktop,
                coverData.render.mobile
              )}
            />
            {coverData.text && (
              <TextGridWrapperAbsolute>
                <RellaxWrapper speed={-7}>
                  <TextGrid items={[coverData.text]} />
                </RellaxWrapper>
              </TextGridWrapperAbsolute>
            )}
          </>
        }
      />
      <Sections toNavigation={false} sections={sectionsData} />
    </FlexBox>
  );
};

export default CategoryWrapper;
